import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrochip, faMemory, faGamepad, faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min.js';


function ClusterNode(props) {

  const [data, setData] = useState([]);
  const [usage, setUsage] = useState({
    percentage: 0,
    percentageColor: "255,210,210,1",
    icon: "faCircle",
    iconColor: '96,177,0,1'
  });

  useEffect(() => {
    const node_hardware_list = props.tres.split(',')
    let node_hardware_used_list = props.tres_used?.split(',')
    node_hardware_used_list = (node_hardware_used_list === undefined) ? [] : node_hardware_used_list
    let node_hardware = node_hardware_list.map(item => (
      {name: item.split('=')[0], total: item.split('=')[1]}
    ))
    const node_hardware_used = node_hardware_used_list.map(item => (
      {name: item.split('=')[0], used: item.split('=')[1]}
    ))
    
    node_hardware = node_hardware.reduce((acc, curr) => {
      const item = { name: curr.name };
      const matchingItem = node_hardware_used.find(el => el.name === curr.name);
      if (matchingItem) {
        Object.assign(item, curr, matchingItem);
      } else {
        Object.assign(item, curr);
      }
      acc.push(item);
      return acc;
    }, []);

    node_hardware = node_hardware.map(item => ({
      ...item,
      used: item.used || 0
    }))

    node_hardware = node_hardware.filter(item => item.name !== 'billing')
    node_hardware = node_hardware.map(item => ({
      ...item,
      name: item.name.replace(/gres\/gpu/g, 'gpu'),
      total: parseInt(parseInt(item.total) / (item.total[item.total.length - 1] === 'M' ? 1000 : 1)),
      used: parseInt(parseInt(item.used) / (item.used[item.used.length - 1] === 'M' ? 1000 : 1)),
      percentage: parseInt(parseInt(parseInt(item.used) / (item.used[item.used.length - 1] === 'M' ? 1000 : 1)) / parseInt(parseInt(item.total) / (item.total[item.total.length - 1] === 'M' ? 1000 : 1)) * 100)
    }))

    setData(node_hardware)

    const usage_max = Math.max(node_hardware.find(item => item.name === 'cpu').percentage, node_hardware.find(item => item.name === 'mem').percentage);
    
    let stateIcon = "";

    let percentageColor = "rgba(255,150,150,0.7)";
    if(usage_max > 80) percentageColor = "rgba(100,0,0,0.7)";
    else if(usage_max > 50) percentageColor = "rgba(100,54,0,0.7)";
    else if(usage_max > 20) percentageColor = "rgba(100,90,0,0.7)";
    else percentageColor = "rgba(57,100,0,0.7)";

    let iconColor = "";
    let iconLabel = "";
    if(["idle", "mixed", "allocated"].includes(props.state) && !props?.state_flags?.includes("DRAIN")){
      stateIcon = faCheckCircle;
      iconColor = "rgba(96,177,0,1)";
      iconLabel = "Node is working properly";
    }
    else{
      iconColor = "rgba(220,120,20,1)";
      iconLabel = "Node is having some issues";
      stateIcon = faExclamationTriangle;
    } 

    setUsage({
      percentage: usage_max,
      percentageColor: percentageColor,
      icon: stateIcon,
      iconColor: iconColor,
      iconLabel: iconLabel
    });

    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl)
    })

  }, [props]);

  if(data.length === 0){
    return (
      <div className="card mb-3" style={{width: "100%"}}>
        <div className="card-header">
          {props.name}
        </div>
        <div className="card-body">
          <div className="card-text container-fluid">
            <div className="row">
              <div className="col col-4">Loading...</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="card mb-3 bg-dark text-light" style={{width: "100%"}}>
      <div className="card-header">
        <h5 className="card-title mb-0"><span>{props.name}</span><span className="float-end"><span style={{color: usage.iconColor}} data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title={usage.iconLabel}><FontAwesomeIcon icon={usage.icon} /></span></span></h5>
      </div>
      <div className="card-body d-flex flex-column" style={{ backgroundImage: `linear-gradient(90deg, ${usage.percentageColor} 0%, ${usage.percentageColor} ${usage.percentage}%, rgba(255,255,255,0) ${usage.percentage}%), repeating-linear-gradient(-45deg,#131313,#131313 10px,#1a1a1a 10px,#1a1a1a 20px)`}}>
        <div className="card-text container-fluid">
          <div className="row">
            {data.map(item => {
              if (item.name === "cpu")
                return <span key={item.name}><FontAwesomeIcon icon={faMicrochip} /> {item.used}/{item.total} cores</span>
              else if (item.name === "mem")
                return <span key={item.name}><FontAwesomeIcon icon={faMemory} /> {item.used}/{item.total} GB mem</span>
              else if (item.name === "gpu")
                return <span key={item.name}><FontAwesomeIcon icon={faGamepad} /> {item.used}/{item.total} gpus</span>
              else
                return <span key={item.name}>{item.name} ???</span>
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClusterNode;