import React, { useState, useEffect } from 'react'; 
import { Navigate } from "react-router-dom";

function Login(props){

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [redirect, setRedirect] = useState(false);
    const [loginError, setLoginError] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({username: username, password: password})
        });
        if (response.status === 429)
            setLoginError("Too much login attempts, please try again after some minutes")
        else if (response.status === 401)
            setLoginError("Incorrect username or password, please try again")
        else if (response.status < 400){
            const data = await response.json();
            if(data.is_authenticated === true){
                setLoginError("")
                setRedirect(true)
                props.parentCallback(true)
            }
        }

    }

    useEffect(() => {
    }, [])

    if (redirect) {
        const currentPath = window.location.pathname.replace('/login', '');
        return <Navigate to={currentPath} replace={true} />
    }

    return (
        <div className="h-100 d-flex align-items-center login">
            <div className="container">
                <div className="row ">
                    <div className="card bg-dark text-light col-10 offset-1 col-lg-6 offset-lg-3">
                        <div className="card-body">
                            <h3 className="mb-3">Login to continue</h3>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group mb-2">
                                    <label htmlFor="username">Username</label>
                                    <input type="text" className="form-control" id="username" aria-describedby="usernameHelp" placeholder="Username" onChange={e => setUsername(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <input type="password" className="form-control" id="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                                </div>
                                <button type="submit" className="btn btn-primary mt-4 px-5">Login</button>
                                {/* <a class="btn btn-primary btn-block" href="/login_sso">
                                    Login using SSO
                                </a> */}
                            </form>
                        </div>
                    </div>
                    {(loginError !== "") && (
                    <div className="card bg-danger text-light col-10 offset-1 col-lg-6 offset-lg-3 mt-4">
                        <div className="card-body">
                            {loginError}
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </div>
    );

}

export default Login;