import React, { useEffect } from 'react'; 
import { Navigate } from "react-router-dom";

function Logout(props){

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(
            props.parentCallback(false)
        )
    }, [props])

    return <Navigate to="/status" replace={true} />

}

export default Logout;