import React, { useState, useEffect } from 'react';
import { Navigate } from "react-router-dom";

import Job from './Job';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart,faFaceSurprise } from '@fortawesome/free-solid-svg-icons';

function MyJobs(props){
    
    const [jobs, setJobs] = useState({
        pending_jobs: [],
        running_jobs: [],
        ended_jobs: [],
        loading: true
    });
    const [redirect, setRedirect] = useState(false);
    
    useEffect(() => {
      const interval = setInterval(() => {
          fetchData();
      }, 15000);
      
      fetchData();
      
      return () => clearInterval(interval);
     
    }, []);
    
      const fetchData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/jobs`);
            const newData = await response.json();
            
            if(response.status === 401) 
              setRedirect(true);

            const alljobs = newData.jobs.filter(item => item.association !== undefined);
            const pending_jobs = alljobs.filter(item => item.state.current === 'PENDING').sort((a, b) => (a.time.submit > b.time.submit) ? 1 : -1);
            const running_jobs = alljobs.filter(item => item.state.current === 'RUNNING').sort((a, b) => (a.time.start > b.time.start) ? 1 : -1);
            const ended_jobs = alljobs.filter(item => !["PENDING", "RUNNING"].includes(item.state.current)).sort((a, b) => (a.time.end < b.time.end) ? 1 : -1);
            setJobs({
                pending_jobs: pending_jobs,
                running_jobs: running_jobs,
                ended_jobs: ended_jobs,
                loading: false
            });
        } catch (error) {
            console.error(error);
        }
      };

      if (redirect) {
        return <Navigate to="/my-jobs/login" replace={true} />
      }

      props.parentCallback(true)
    
      if (!jobs) {
     return ""
      }
      
      return (
     <div className="container text-light">
        <h2 className="mb-3">Running Jobs <span className="badge bg-success">{jobs.running_jobs.length}</span></h2>
        <div className="row">
          {
            (jobs.loading) ? (
              <p className="text-center pb-5 pt-5">
                <div className="spinner-grow text-light" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </p>
            ) :
            (jobs.running_jobs.length === 0) ? (
              <div className="d-flex align-items-stretch mt-5 mb-5">
                <div class="card bg-dark text-light mx-auto">
                  <div class="card-body">
                    There are no running jobs! <FontAwesomeIcon icon={faFaceSurprise} />
                  </div>
                </div>
              </div>
            ) : (
                jobs.running_jobs.map(item => (
                    <div className="col-lg-6 d-flex align-items-stretch" key={item.job_id}>
                        <Job id={item.job_id} name={item.name} username={item.association.user} state={item.state.current} node={item.nodes} time_started={item.time.start} time_running={item.time.elapsed} />
                    </div>
                ))
            )
          }
        </div>
        <h2 className="mb-3 mt-2">Pending Jobs <span className="badge bg-warning">{jobs.pending_jobs.length}</span></h2>
        <div className="row">
          {
            (jobs.loading) ? (
              <p className="text-center pb-5 pt-5">
                <div className="spinner-grow text-light" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </p>
            ) :
            (jobs.pending_jobs.length === 0) ? (
                  <div className="d-flex align-items-stretch mt-5 mb-5">
                    <div class="card bg-dark text-light mx-auto">
                      <div class="card-body">
                        There are no pending jobs! Hurray! <FontAwesomeIcon icon={faHeart} />
                      </div>
                    </div>
                  </div>
            ) : (
                jobs.pending_jobs.map(item => (
                  <div className="col-lg-6 d-flex align-items-stretch" key={item.job_id}>
                    <Job id={item.job_id} name={item.name} username={item.association.user} state={item.state.current} time_waiting={parseInt(Date.now()/1000)-item.time.submission} waiting_reason={item.state.reason} />
                  </div>
                ))
            )
          }
        </div>
        <h2 className="mb-3 mt-2">Recent Ended Jobs <span className="badge bg-primary">{jobs.ended_jobs.length}</span></h2>
        <div className="row">
          {
            (jobs.loading) ? (
              <p className="text-center pb-5 pt-5">
                <div className="spinner-grow text-light" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </p>
            ) :
            (jobs.ended_jobs.length === 0) ? (
              <div className="d-flex align-items-stretch mt-5 mb-5">
                <div class="card bg-dark text-light mx-auto">
                  <div class="card-body">
                    There are no recent ended jobs! <FontAwesomeIcon icon={faFaceSurprise} />
                  </div>
                </div>
              </div>
            ) : (
                jobs.ended_jobs.map(item => (
                <div className="col-lg-6 d-flex align-items-stretch" key={item.job_id}>
                    <Job id={item.job_id} name={item.name} username={item.association.user} state={item.state.current} node={item.nodes} time_started={item.time.start} time_running={item.time.elapsed} time_ended={item.time.end} exit_status={item.exit_code.status} exit_code={item.exit_code.return_code} />
                </div>
                ))
            )
          }
        </div>
     </div>
      );
}

export default MyJobs;