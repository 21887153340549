import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCircle } from '@fortawesome/free-solid-svg-icons';

import CancelButton from './CancelButton';

function Job(props){

    const [data] = useState({
        id: props.id,
        name: props.name,
        username: props.username,
        state: props.state,
        node: props.node,
        time_started: to_human_datetime(props.time_started),
        time_running: to_human_timeperiod(props.time_running),
        time_ended: to_human_datetime(props.time_ended),
        time_waiting: to_human_timeperiod(props.time_waiting),
        waiting_reason: to_human_reason(props.waiting_reason),
        exit_status: props.exit_status,
        exit_code: props.exit_code,
        stateIcon: (<FontAwesomeIcon icon={faCircle} className={(props.state==='COMPLETED') ? "text-success" : "text-danger"} />),
    });

    function to_human_datetime(timestamp){
        const date = new Date(timestamp * 1000);
        return date.toLocaleString();
    }

    function to_human_timeperiod(seconds){
        if(seconds < 60) return `${seconds} seconds`;
        else if(seconds < 3600) return `${Math.floor(seconds / 60)} minutes`;
        else if(seconds < 86400) return `${Math.floor(seconds / 3600)} hours, ${Math.floor((seconds % 3600) / 60)} minutes`;
        else return `${Math.floor(seconds / 86400)} days, ${Math.floor((seconds % 86400) / 3600)} hours`;
    }

    function to_human_reason(reason){
        if(reason === "None") return "Waiting for resources to become available";
        else if(reason === "QOSMaxJobsPerUserLimit") return "Max jobs per user limit reached on chosen queue";
        else if(reason === "QOSMaxNodePerUserLimit") return "Max nodes per user limit reached on chosen queue";
        else if(reason === "ReqNodeNotAvail") return "Node specifically requested is not available at this time";
        else if(reason === "Resources") return "The job is waiting for resources to become available";
        else return reason;
    }

    useEffect(() => {
    }, [props]);


    return (
        <div className="card mb-3 bg-dark text-light" style={{width: "100%"}}>
            <div className="card-header">
                <div className="row">
                    <div className="col-3">
                        <span className="card-title mb-0 fs-6">#{data.id}</span>
                    </div>
                    <div className="col-5 text-center my-auto" style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>
                        <span className="font-monospace fs-6">{data.name}</span>
                    </div>
                    <div className="col-4" style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>
                        <span className="float-end fs-6 my-auto"><FontAwesomeIcon icon={faUser} /> {data.username}</span>
                    </div>
                </div>
            </div>
            <div className={`card-body d-flex flex-column ${(data.state === "RUNNING") ? "card-running" : ((data.state === "PENDING") ? "card-pending" : "card-done" )}`}>
                <div className="card-text container-fluid">
                    { (data.state === "RUNNING") ? (
                        <div className="row">
                            <span>Running on {data.node}</span>
                            <span>Started on {data.time_started}</span>
                            <span>Been running for {data.time_running}</span>
                            <span class="text-end"><CancelButton job_id={data.id} /></span>
                        </div>
                    ) : (data.state === "PENDING") ? (
                        <div className="row">
                            <span>Been waiting for {data.time_waiting}</span>
                            <span>{data.waiting_reason}</span>
                            <span class="text-end"><CancelButton job_id={data.id} /></span>
                        </div>
                    ) : (
                        <div className="row">
                            <span>State {data.stateIcon} {data.state} 
                                { (data.state === "COMPLETED") ? 
                                (` / ${data.exit_status} with exit code ${data.exit_code}`)
                                : "" 
                                }
                            </span>
                            <span>Running from {data.time_started} to {data.time_ended}</span>
                            <span>Total execution time {data.time_running}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Job;