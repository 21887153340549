import { useState } from 'react';
import { useSpring, animated } from 'react-spring';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faWarning, faTrash } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';

function CancelButton(props) {
  const [isConfirming, setIsConfirming] = useState(false);
  const job_id = props.job_id;
  const [cancelTimeout, setCancelTimeout] = useState(null);
  const [isRemoving, setIsRemoving] = useState(false);

  const confirmAnimation = useSpring({
    transform: isConfirming ? 'scale(1.1)' : 'scale(1)',
    boxShadow: isConfirming ? '0 0 10px rgba(0, 0, 0, 0.3)' : 'none',
  });

  const handleButtonClick = () => {
    if (isConfirming) {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/canceljob`, {
            job_id: job_id
            })
            .then(response => {
            console.log(response);
            })
            .catch(error => {
            console.log(error);
            });
        setIsRemoving(true);
        clearInterval(cancelTimeout);
        setCancelTimeout(null);
    } else {
        setIsConfirming(true);
        setCancelTimeout(setTimeout(() => {
            setIsConfirming(false);
        }, 5000));
    }
  };

    if (isRemoving) {
        return (
            <animated.button style={confirmAnimation} type="button" className="btn btn-danger btn-sm disabled">
                <span><FontAwesomeIcon icon={faTrash} /> Cancelling job...</span>
            </animated.button>
        );
    }
    
    return (
        <animated.button style={confirmAnimation} type="button" className="btn btn-danger btn-sm" onClick={handleButtonClick}>
        {isConfirming ? 
            (<span><FontAwesomeIcon icon={faWarning} /> Are you sure?</span>) : 
            (<span><FontAwesomeIcon icon={faTrashCan} /> Cancel Job</span>)}
        </animated.button>
    );
}

export default CancelButton;