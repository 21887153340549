import React, { useState, useEffect } from 'react';

import NewsItem from './NewsItem';

function News(props) {

    const [news, setNews] = useState([]);
    const [news_loaded, setNewsLoaded] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchData();
        }, 10000);

        fetchData();

        return () => clearInterval(interval);
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/news`);
            const newData = await response.json();
            setNews(newData);
            setNewsLoaded(true);
        }
        catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="container text-light">
            <h2 className="mb-3">News</h2>
            <div className="row">
                {
                    (news_loaded === false) ?
                        (<p className="text-center pb-5 pt-5">
                            <div className="spinner-grow text-light" role="status">
                            <span className="visually-hidden">Loading...</span>
                            </div>
                        </p>)
                    :

                    news.map(item => (
                        <div className="col-md-6 d-flex align-items-stretch" key={item.title}>
                            <NewsItem title={item.title} datetime={item.datetime} content={item.content} />
                        </div> 
                    ))

                }
            </div>
            <h2 className="mb-3 mt-2">Information</h2>
            <div className="card bg-dark text-light ">
                <div className="card-body">
                    <div class="row">
                        <div class="col col-12 col-lg-6">
                            <h4>About the HPC Cluster</h4>
                            <p>Jobs and resources are managed using SLURM. There is a total of <strong>9</strong> nodes for general computing, <strong>1</strong> <em>fat node</em> for memory-intensive jobs and <strong>4</strong> nodes for GPU computing. There is a total of 220 TB of distributed storage.</p>
                            <h4>Getting access</h4>
                            <p>To be able to access, you need the <strong>Cluster Computación (ctcomp3)</strong> permission set in your account. You can check <a href="https://citius.gal/me">your permissions here</a>. To request access, <a href="https://gitlab.citius.usc.es/citius/issues/-/issues/new">send an issue</a> asking for it.</p>
                            <p>To connect, you need to SSH into <code>hpc-login2.inv.usc.es</code> with your username and password. If you are not connected to the wired network in the building, you need to <a href="https://nextcloud.citius.usc.es/apps/files/?dir=/VPN%20Files&fileid=4417914">connect to the VPN first</a>.</p>
                            <h4>Available Software</h4>
                            <p>Not all the available software is ready to use right away, and must be added into the environment using Lmod commands. Use the command <code>module avail</code> to see the available software modules.</p>
                            <p>To install additional software or software versions, <a href="https://gitlab.citius.usc.es/citius/issues/-/issues/new">send an issue</a> with your request.</p>
                        </div>
                        <div class="col col-12 col-lg-6">
                            <h4>Basic Usage</h4>
                            <p>To run a job, you need to ask for resources and send the commands to execute to SLURM. One simple way to do so is create a bash script like this one:</p>
                            <pre style={{lineHeight: 1.2}}><code>#!/bin/bash<br />#SBATCH --job-name=mytest            # Job name<br />#SBATCH --nodes=1                    # -N Run all processes on a single node<br />#SBATCH --ntasks=1                   # -n Run a single task<br />#SBATCH --cpus-per-task=1            # -c Run 1 processor per task<br />#SBATCH --mem=1gb                    # Job memory request<br />#SBATCH --time=05:00:00              # Time limit hrs:min:sec<br />#SBATCH --qos=regular                # Queue name<br />#SBATCH --output=mytest_%j.log       # Standard output and error log<br /><br />echo "Hello World!"</code></pre>
                            <p>Put all the commands you need to run inside this script. Then send it to SLURM using <code>sbatch</code>:</p>
                            <pre><code>$ sbatch mytest.sh</code></pre>
                            <p>When running real-world jobs, please adjust the queue and resources requirements. Incorrect requirements might end up with unnecessary delays, impossible to run jobs, or cancelled jobs. You can get more information in <a href="https://wiki.citius.usc.es/en:centro:servizos:hpc">the full documentation</a>.</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
                
}

export default News;