import React, { useState, useEffect } from 'react';

function NewsItem(props) {

    const [data, setData] = useState({
        datetime: "",
        title: "",
        content: ""
    });

    useEffect(() => {
        setData({
            datetime: to_human_relative_datetime(props.datetime),
            title: props.title,
            content: props.content
        });
    }, [props.datetime, props.title, props.content]);

    function to_human_relative_datetime(timestamp){
        const now = new Date() / 1000;
        const date = Date.parse(timestamp) / 1000
        const diff = Math.floor((now - date));
        if (diff < 0) return 'right now';
        else if(diff < 60) return `${diff} seconds ago`;
        else if(diff < 3600) return `${Math.floor(diff / 60)} minutes ago`;
        else if(diff < 86400) return `${Math.floor(diff / 3600)} hours ago`;
        else return `${Math.floor(diff / 86400)} days ago`;
    }

    return (
        <div className="card mb-3 bg-dark text-light" style={{width: "100%"}}>
            <div className="card-header">
                <div className="row">
                    <div className="col-9">
                        <span className="fs-6"><strong>{data.title}</strong></span>
                    </div>
                    <div className="col-3">
                        <span className="card-title mb-0 fs-6 float-end"><em>{data.datetime}</em></span>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <p className="card-text">{data.content}</p>
            </div>
        </div>
    );

}

export default NewsItem;