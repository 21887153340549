import React, { useState, useEffect } from 'react';
import ClusterNode from './ClusterNode';
import ClusterQueues from './ClusterQueues';
import { Navigate } from "react-router-dom";

function ClusterStatus(props) {
  const [status, setStatus] = useState({});
  const [globalusage, setGlobalUsage] = useState(0);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 10000);

    fetchData();

    return () => clearInterval(interval);
    
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/nodes`);
      const newData = await response.json();

      if(response.status === 401) 
        setRedirect(true);
      
      setStatus(newData);

      let max_usages = [];
      newData.nodes.forEach(node => {

        const node_hardware_list = node.tres.split(',')
        let node_hardware_used_list = node.tres_used?.split(',')
        node_hardware_used_list = (node_hardware_used_list === undefined) ? [] : node_hardware_used_list
        let node_hardware = node_hardware_list.map(item => (
          {name: item.split('=')[0], total: item.split('=')[1]}
        ))
        const node_hardware_used = node_hardware_used_list.map(item => (
          {name: item.split('=')[0], used: item.split('=')[1]}
        ))

        node_hardware = node_hardware.reduce((acc, curr) => {
          const item = { name: curr.name };
          const matchingItem = node_hardware_used.find(el => el.name === curr.name);
          if (matchingItem) {
            Object.assign(item, curr, matchingItem);
          } else {
            Object.assign(item, curr);
          }
          acc.push(item);
          return acc;
        }, []);

        node_hardware = node_hardware.map(item => ({
          ...item,
          used: item.used || 0
        }))
    
        node_hardware = node_hardware.filter(item => item.name !== 'billing')
        node_hardware = node_hardware.map(item => ({
          ...item,
          name: item.name.replace(/gres\/gpu/g, 'gpu'),
          total: parseInt(parseInt(item.total) / (item.total[item.total.length - 1] === 'M' ? 1000 : 1)),
          used: parseInt(parseInt(item.used) / (item.used[item.used.length - 1] === 'M' ? 1000 : 1)),
          percentage: parseInt(parseInt(parseInt(item.used) / (item.used[item.used.length - 1] === 'M' ? 1000 : 1)) / parseInt(parseInt(item.total) / (item.total[item.total.length - 1] === 'M' ? 1000 : 1)) * 100)
        }))

        const usage_max = Math.max(node_hardware.find(item => item.name === 'cpu').percentage, node_hardware.find(item => item.name === 'mem').percentage);

        max_usages.push(usage_max);

      });

      setGlobalUsage((max_usages.reduce((a, b) => a + b, 0) / max_usages.length) || 0);

    } catch (error) {
      console.error(error);
    }
  };

  if (redirect) {
    return <Navigate to="/status/login" replace={true} />
  }

  props.parentCallback(true)

  if (!status || !status.nodes) {
    return ""
  }
  
  return (
    <div className="container text-light">
      <h2 className="mb-3">Global Usage</h2>
      <ClusterQueues globalusage={globalusage} />
      
      <h2 className="mb-3">Nodes</h2>
      <div className="row">
        {
          status.nodes.map(item => (
          <div className="col-md-4 d-flex align-items-stretch" key={item.name}>
            <ClusterNode name={item.name} tres_used={item.tres_used} tres={item.tres} state={item.state} state_flags={item.state_flags} />
          </div>
          ))
        }
      </div>
    </div>
  );
}

export default ClusterStatus;