import React, { useState, useEffect } from 'react'; 
import { NavLink, Route, Routes, Navigate } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff, faTemperatureHalf, faGears, faNewspaper } from '@fortawesome/free-solid-svg-icons';

import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import ClusterStatus from './ClusterStatus';
import MyJobs from './MyJobs';
import News from './News';
import Login from './Login';
import Logout from './Logout';

function Redirection() {
  return (
    <Navigate to="/news" replace={true} />
  );
}

function App() {

  const [isLoged, setIsLoged] = useState(false)

  function handleCallback(childData){
    setIsLoged(childData)
  }

  useEffect(() => {
    setIsLoged(isLoged)
  }, [isLoged])
  
  return (
    <div className="App bg-app">
        <header className="mb-5">
          <nav className="navbar navbar-dark navbar-expand-lg bg-dark">
            <div className="container">
              <a className="navbar-brand" href="/">HPC Cluster ctcomp3</a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse ms-auto" id="navbarNavAltMarkup">
                <div className="navbar-nav ms-auto">
                  <NavLink className="nav-link ms-lg-4" to="/news"><FontAwesomeIcon icon={faNewspaper} /> News</NavLink>
                  <NavLink className="nav-link ms-lg-4" to="/status"><FontAwesomeIcon icon={faTemperatureHalf} /> Status</NavLink>
                  <NavLink className="nav-link ms-lg-4" to="/my-jobs"><FontAwesomeIcon icon={faGears} /> My Jobs</NavLink>
                </div>
              </div>
            </div>
          </nav>
        </header>  
        <main className="h-100">
          <Routes>
            <Route path="/" element={<Redirection />} />
            <Route path="/status" element={<ClusterStatus parentCallback = {handleCallback} />}/>
            <Route path="/my-jobs" element={<MyJobs parentCallback = {handleCallback} />}/>
            <Route path="/news" element={<News parentCallback = {handleCallback} />}/>
            <Route path="/login" element={<Login parentCallback = {handleCallback} />}/>
            <Route path="/status/login" element={<Login parentCallback = {handleCallback} />}/>
            <Route path="/my-jobs/login" element={<Login parentCallback = {handleCallback} />}/>
            <Route path="/logout" element={<Logout parentCallback = {handleCallback} />}/>
          </Routes>
        </main>
        <footer className="mt-5 bg-dark text-light"  style={{"--bs-bg-opacity": ".8"}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center pt-3">
                <p className="text-muted">
                  Version {process.env.REACT_APP_VERSION} built on {process.env.REACT_APP_BUILDTIMESTAMP}
                  { isLoged && ( <NavLink to="/logout" className="ms-3 text-light"><FontAwesomeIcon icon={faPowerOff} /> Logout</NavLink> ) }
                </p>
              </div>
            </div>
          </div>
        </footer>  
    </div>
  );
}

export default App;
