import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min.js';

function ClusterQueues(props){
    
    const [jobs, setJobs] = useState({
        pending_jobs: [],
        running_jobs: [],
        ended_jobs: [],
        jobs_loaded: false,
        globalusage: props.globalusage,
        globalusageColor: get_usage_color(props.globalusage),
        averageWaitingTime: null,
        background_styles: {width: "100%", backgroundImage: `repeating-linear-gradient(-45deg,#131313,#131313 10px,#1a1a1a 10px,#1a1a1a 20px)`},
    });

    function get_human_readable_time_period(seconds){
      let time_period = "";
      if(seconds > 86400) time_period = Math.round(seconds / 86400) + " days, " + Math.round((seconds % 86400) / 3600) + " hours";
      else if(seconds > 3600) time_period = Math.round(seconds / 3600) + " hours, " + Math.round((seconds % 3600) / 60) + " minutes";
      else if(seconds > 60) time_period = Math.round(seconds / 60) + " minutes";
      else time_period = seconds + " seconds";
      return time_period;
    }
    
  
    function get_usage_color(usage){
      let usage_color = "rgba(255,150,150,0.7)";
      if(usage > 80) usage_color = "rgba(100,0,0,0.7)";
      else if(usage > 50) usage_color = "rgba(100,54,0,0.7)";
      else if(usage > 20) usage_color = "rgba(100,90,0,0.7)";
      else usage_color = "rgba(57,100,0,0.7)";
      return usage_color;
    }
    
    useEffect(() => {
      const interval = setInterval(() => {
          fetchData();
      }, 10000);
    
      fetchData();
      
      return () => clearInterval(interval);
      
    }, [props.globalusage, props.AverageWaitingTime]);
      
    const fetchData = async () => {
      try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/jobs`);
          const newData = await response.json();
          const alljobs = newData.jobs;
          const pending_jobs = alljobs.filter(item => item.state.current === 'PENDING');
          const pending_waiting_resources_jobs = pending_jobs.filter(item => item.state.reason.substr(0,3) !== "QOS");
          const running_jobs = alljobs.filter(item => item.state.current === 'RUNNING');
          const ended_jobs = alljobs.filter(item => !["PENDING", "RUNNING"].includes(item.state.current));
          const averageWaitingTime = (pending_waiting_resources_jobs.length === 0 ) ? 0 : pending_waiting_resources_jobs.reduce((acc, item) => acc + parseInt(Date.now()/1000)-item.time.submission, 0) / pending_waiting_resources_jobs.length;
          setJobs({
              pending_jobs: pending_jobs,
              running_jobs: running_jobs,
              ended_jobs: ended_jobs,
              jobs_loaded: true,
              globalusage: props.globalusage,
              averageWaitingTime: get_human_readable_time_period(averageWaitingTime),
              background_styles: {width: "100%", backgroundImage: `linear-gradient(90deg, ${get_usage_color(props.globalusage)} 0%, ${get_usage_color(props.globalusage)} ${props.globalusage}%, rgba(255,255,255,0) ${props.globalusage}%), repeating-linear-gradient(-45deg,#131313,#131313 10px,#1a1a1a 10px,#1a1a1a 20px)`}
          });

      } catch (error) {
          console.error(error);
      }
    };
      
    if (!jobs) {
      return ""
    }

    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl)
    })
      
      return (
        <div className="card mb-3 bg-dark text-light" style={jobs.background_styles}>
          <div className="card-text container-fluid pt-3 ps-4">
            <h3>About <span className="fs-1">{ (jobs.jobs_loaded !== true) ? <div className="spinner-grow spinner-grow-lg" role="status"><span className="visually-hidden">?</span></div> : Math.ceil(jobs.globalusage)}%</span> current usage</h3>
            <h4> <span className="badge bg-success">{ (jobs.jobs_loaded !== true) ? <div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">?</span></div> : jobs.running_jobs.length} Running Jobs</span> <span className="badge bg-warning">{ (jobs.jobs_loaded !== true) ? <div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">?</span></div> : jobs.pending_jobs.length} Pending Jobs</span></h4>
            <p className="mb-3">
              { (jobs.averageWaitingTime === null) ? "Calculating averate waiting time..." : `Average waiting time for new jobs is ${jobs.averageWaitingTime}`}&nbsp;
              <FontAwesomeIcon icon={faInfoCircle} data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="This is calculated using the current waiting time from all pending jobs waiting for resources" />
            </p>
          </div>
        </div>
      );
}

export default ClusterQueues;